<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <user-manager-view-info-card
            :user-data="userData"
            @deactivated="get_user_data"
            @reactivated="get_user_data"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="userData.permissions"
          cols="12"
          lg="12"
        >
          <permissions-card
            :disabled="true"
            :user-permissions="userData.permissions"
          />
          <!-- <user-manager-view-permissions-card :user-data="userData" /> -->
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'

import PermissionsCard from '@/views/user-management-system/components/PermissionsCard.vue'

import UserManagerViewInfoCard from './UserManagerViewInfoCard.vue'
import UserManagerViewPermissionsCard from './UserManagerViewPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    PermissionsCard,

    // Local Components
    UserManagerViewInfoCard,
    UserManagerViewPermissionsCard,
  },
  data: () => ({
    userData: {},
  }),
  mounted() {
    this.get_user_data()
  },
  methods: {
    get_user_data() {
      const { id } = this.$route.params

      this.$http
        .get(`/api/users/${id}`)
        .then(response => {
          this.userData = response.data.user
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', "Error retrieving the user's record")

          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>
